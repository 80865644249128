import OfflineMessage from '@components/OfflineMessage';
import { OfflineProvider } from '@context/OfflineContext';
import useLocalStorage from '@hooks/useLocalStorage';
import { theme } from '@theme';
import { motion } from 'framer-motion';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
  }
  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textDark};
    background-color: ${({ theme }) => theme.background.primary['100']};

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;

    color: #5F698C;
  }
  html, body {
    min-height: 100vh;
  }
  #__next {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h1{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 48px;
    /* or 133% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h2{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.625rem;
    line-height: 30px;
    /* or 115% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 30px;
    /* or 115% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h4{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 30px;
    /* or 125% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h5{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    /* or 138% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

  h6{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 22px;
    /* or 138% */

    display: flex;
    align-items: center;

    color: #5F698C;
  }

`;

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [utm, setUtm] = useLocalStorage('utm', {});

  useEffect(() => {

    if (typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined) {

      const wb = window.workbox;

      const promptNewVersionAvailable = event => {
        // eslint-disable-next-line max-len
        if (confirm('A newer version of this web app is available, reload to update?')) {
          wb.addEventListener('controlling', event => {
            window.location.reload();
          });

          wb.messageSkipWaiting();
        } else {
          console.log(
            // eslint-disable-next-line max-len
            'User rejected to reload the web app, keep using old version. New version will be automatically load when user open the app next time.'
          );
        }
      };

      wb.addEventListener('waiting', promptNewVersionAvailable);

      wb.register();
    }
    document.body
      .setAttribute('style', 'overflow-y: auto');
  }, []);

  useEffect(()=>{
    const utmQuery = utm;

    if (Object.keys(router.query).length !== 0){
      Object.keys(router.query).forEach(res=>{
        if (res.includes('utm')){
          utmQuery[res] = router.query[res];
        }
      });
    }

    setUtm(utmQuery);
  }, [router]);

  if (typeof window !== 'undefined') {
    window.addEventListener('load', function() {
      document
        .getElementById('viewport')
        .setAttribute('content', 'width=' + window.innerWidth +
        ', height=' + window.innerHeight +
        ', initial-scale=1.0');
    });

    const globalMaxHeightChange = () => {
      // Executa o mesmo script de antes
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', globalMaxHeightChange);
  }
  return (
    <OfflineProvider value={false}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <motion.div
          key={router.route}
          initial="pageInitial"
          animate="pageAnimate"
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          variants={{
            pageInitial: {
              opacity: 0
            },
            pageAnimate: {
              opacity: 1
            },
            pageExit: {
              backgroundColor: 'white',
              filter: 'invert()',
              opacity: 0
            },
          }}>
          <OfflineMessage/>
          <Component {...pageProps} />
        </motion.div>
      </ThemeProvider>
    </OfflineProvider>
  );
}
