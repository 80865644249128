// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://cc91d38f6e2b4438baa8f47ea7f74e93@o1081134.ingest.sentry.io/6122731',
  // Adjust this value in production, or use tracesSampler for greater control
  // if VERCEL_ENV === 'production' or undefined (local) SampleRate === 100%
  // else SampleRate === 1%
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.VERCEL_ENV === 'production'
    || process.env.VERCEL_ENV === undefined
    ? 1.0
    : 0.01,
  tunnel: "/api/tunnel",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
