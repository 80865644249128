import { createContext, Dispatch,
  ReactNode, SetStateAction, useContext, useState } from 'react';

export interface OfflineValues {
  offline: boolean;
  setOffline?: Dispatch<SetStateAction<boolean>>
}

const initialValues: OfflineValues = {
  offline: false
};

export const OfflineContext = createContext<OfflineValues>(initialValues);

export interface OfflineProviderProps {
  children: ReactNode,
  value: boolean
}

export function OfflineProvider(props: OfflineProviderProps) {
  const [offline, setOffline] =
    useState<boolean>(props.value);

  const updateStatus = ()=>{
    setOffline(!navigator.onLine);
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', updateStatus);
  }

  return (
    <OfflineContext.Provider
      {...props}
      value={{ offline, setOffline }}
    />
  );
}
