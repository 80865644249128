import { OfflineContext } from '@context/OfflineContext';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledOfflineMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: #FF5959;
  color: #FFFBF7;
  position: ${({ scrollPosition })=>scrollPosition>0? 'fixed': 'relative'};
  z-index: 100;
`;

const OfflineMessage = () => {
  const { offline } = useContext(OfflineContext);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(()=>{
    const updatePosition = ()=>{
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', updatePosition);

    return ()=> window.removeEventListener('scroll', updatePosition);
  }, []);

  //TODO: add icone
  return (
    offline &&
    <StyledOfflineMessage scrollPosition={scrollPosition}>Sem conexão com a internet.</StyledOfflineMessage>
  );
};

export default OfflineMessage;
