import { ThemeInterface } from '@interface/theme';

/* eslint-disable max-len */
const spacing = (factor = 2) => {
  return `${0.25 * factor}rem`;
};

const fontFamily = 'Open Sans';

const theme: ThemeInterface = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  backgrouHeader: 'linear-gradient(90.02deg, #FEAF6B 0.49%, #FE9090 52.18%, #B982EA 99.14%)',
  colors: {
    primary: {
      default: '#F8933B',
      '100': '#FCEEE3',
      '200': '#FDD3AF',
      '300': '#FEAF6B',
      '400': '#F8933B',
      '500': '#EC7712',
      '600': '#DB6500',
      '700': '#BE5800',
      '800': '#773402',
    },
    green: {
      default: '#50D19F',
      '100': '#C7FFE9',
      '200': '#94F3CE',
      '300': '#75ECBE',
      '400': '#50D19F',
      '500': '#27B980',
      '600': '#139562',
      '700': '#086B45',
      '800': '#055738',
    },
    secundary: {
      default: '#9D5FD3',
      '100': '#F1E3FD',
      '200': '#DEB9FE',
      '300': '#B982EA',
      '400': '#9D5FD3',
      '500': '#8D40D0',
      '600': '#7925C1',
      '700': '#5D109F',
      '800': '#370661',
    },
    blue: {
      default: '#52C9CC',
      '100': '#C6FDFF',
      '200': '#A6EEF0',
      '300': '#80DBDD',
      '400': '#52C9CC',
      '500': '#25B6BA',
      '600': '#0D9EA1',
      '700': '#0A7D81',
      '800': '#036063',
    },
    salmon: {
      default: '#FF6B6B',
      '100': '#FFE7E7',
      '200': '#FFB1B1',
      '300': '#FE9090',
      '400': '#FF6B6B',
      '500': '#EC5050',
      '600': '#D1343E',
      '700': '#AE182A',
      '800': '#870B1A',
    },
    neutral: {
      '100': '#DDE6F4',
      '200': '#A2ABCB',
      '300': '#5F698C',
      '400': '#2C3143',
    }
  },
  textLight: '#fff',
  textDark: '#000',
  background: {
    primary: {
      default: '#FCF1E8',
      '100': '#FFFBF7',
      '200': '#FCF7F2',
      '300': '#FCF1E8',
    },
    secundary: {
      default: '#EBF1FA',
      '100': '#FCFCFC',
      '200': '#FAFCFF',
      '300': '#EBF1FA',
    }
  },
  semantics: {
    red: {
      default: '#FF5959',
      '100': '#FCE4E4',
      '200': '#F7C3C3',
      '300': '#FF7A7A',
      '400': '#FF5959',
    },
    green: {
      default: '#12BA4F',
      '100': '#D4F7E1',
      '200': '#9DECBA',
      '300': '#4CDC80',
      '400': '#12BA4F',
    },
    blue: {
      default: '#4AA1FF',
      '100': '#C7E2FF',
      '200': '#8FC5FF',
      '300': '#69B1FF',
      '400': '#4AA1FF',
    }
  },
  spacing,
  typography: {
    fontFamily,
    fontSize: '16px',
    h1: {
      fontFamily,
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    h2: {
      fontFamily,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    h3: {
      fontFamily,
      fontSize: '1.17rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    h4: {
      fontFamily,
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    h5: {
      fontFamily,
      fontSize: '0.83rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    h6: {
      fontFamily,
      fontSize: '0.67rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    p: {
      fontFamily,
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    },
    text: {
      fontFamily,
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: 'white',
      color: 'black'
    }
  },
  button: {
    primary: {
      background: '#F09554',
      boxShadow: '0px 4px 50px rgba(18, 50, 166, 0.08)',
      border: 0,
      borderRadius: '12px',
      color: '#44464D',
      fontFamily: 'Plus Jakarta Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.125rem',
      lineHeight: '22px',
      active: {
        background: '#F8933B;',
        boxShadow: '0px 4px 50px rgba(18, 50, 166, 0.08)',
        borderRadius: '12px',
      },
      disabled: {
        color: '#F9F9F9',
        background: '#E8CAB5',
        borderRadius: '12px',
      },
      hover: {
        background: '#905932',
        boxShadow: '0px 4px 50px rgba(18, 50, 166, 0.08)',
        borderRadius: '12px',
        color: '#F9F9F9',
      }
    },
    secundary: {
      background: '#FFFBF7',
      borderRadius: '60px',
      boxShadow: '',
      border: 0,
      color: '#5F698C',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '22px',
      active: {
        color: '#FFFBF7',
        background: '#2C3143',
        boxShadow: '0px 4px 50px rgba(18, 50, 166, 0.08)',
        borderRadius: '60px',
      },
      disabled: {
        background: '#EBF1FA',
        borderRadius: '60px',
      },
      hover: {
        color: '#FFFBF7',
        background: '#5F698C',
        boxShadow: '0px 4px 50px rgba(18, 50, 166, 0.08)',
        borderRadius: '60px',
      }
    },
    tertiary: {
      background: '#FFFBF7',
      borderRadius: '60px',
      boxShadow: '',
      border: '1.5px solid #5F698C',
      color: '#5F698C',
      filter: 'drop-shadow(0px 4px 50px rgba(18, 50, 166, 0.08))',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '22px',
      active: {
        background: '#2C3143',
        color: '#FFFBF7',
        borderRadius: '60px',
      },
      disabled: {
        color: '#A2ABCB',
        border: '1.5px solid #A2ABCB',
        filter: 'drop-shadow(0px 4px 50px rgba(18, 50, 166, 0.08))',
        borderRadius: '60px',
      },
      hover: {
        color: '#FFFBF7',
        background: '#5F698C',
        borderRadius: '60px',
      }
    },
    quaternary: {
      background: '',
      borderRadius: '60px',
      boxShadow: '',
      border: 0,
      color: '#5F698C',
      filter: 'drop-shadow(0px 4px 50px rgba(18, 50, 166, 0.08))',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1.125rem',
      lineHeight: '22px',
      active: {
        background: '#DDE6F4',
        color: '#2C3143',
        borderRadius: '60px',
      },
      disabled: {
        color: '#A2ABCB',
        filter: 'drop-shadow(0px 4px 50px rgba(18, 50, 166, 0.08))',
        borderRadius: '60px',
      },
      hover: {
        color: '#2C3143',
        background: '#EBF1FA',
        borderRadius: '60px',
      }
    }
  },
  caption: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: 'white',
    color: 'black'
  },
  tooltip: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: 'white',
    color: 'black'
  },
  dropdown: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: 'white',
    color: 'black'
  },
  progressbar: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: 'white',
    color: 'black'
  },
  levelbar: {
    fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: 'white',
    color: 'black'
  }
};

export { theme };

// Device width guide
// https://blog.apiki.com/media-queries-breakpoints-projetos-mobile-first/
// @media (min-width:320px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
// @media (min-width:480px) { /* smartphones, Android phones, landscape iPhone */ }
// @media (min-width:600px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
// @media (min-width:801px) { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
